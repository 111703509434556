export const idaData = {
  "Project Total": [
    {
      Month: "Sep-23",
      "Off-Site": null,
      "On-Site": null,
      Total: null,
    },
    {
      Month: "Oct-23",
      "Off-Site": null,
      "On-Site": null,
      Total: null,
    },
    {
      Month: "Nov-23",
      "Off-Site": null,
      "On-Site": null,
      Total: null,
    },
    {
      Month: "Dec-23",
      "Off-Site": null,
      "On-Site": null,
      Total: null,
    },
    {
      Month: "Jan-24",
      "Off-Site": null,
      "On-Site": null,
      Total: null,
    },
    {
      Month: "Feb-24",
      "Off-Site": null,
      "On-Site": null,
      Total: null,
    },
    {
      Month: "Mar-24",
      "Off-Site": null,
      "On-Site": null,
      Total: null,
    },
    {
      Month: "Apr-24",
      "Off-Site": null,
      "On-Site": 10054.2,
      Total: 10054.2,
    },
    {
      Month: "May-24",
      "Off-Site": null,
      "On-Site": 10712.1,
      Total: 10712.1,
    },
    {
      Month: "Jun-24",
      "Off-Site": null,
      "On-Site": 11620.7,
      Total: 11620.7,
    },
    {
      Month: "Jul-24",
      "Off-Site": 190.7,
      "On-Site": 9979.4,
      Total: 10170.1,
    },
    {
      Month: "Aug-24",
      "Off-Site": null,
      "On-Site": null,
      Total: null,
    },
    {
      Month: "YTD",
      "Off-Site": 190.7,
      "On-Site": 42366.3,
      Total: 42557.0,
    },
    {
      Month: "Targets",
      "Off-Site": null,
      "On-Site": null,
      Total: null,
    },
  ],
  "Pillar A": {
    "Equity Seeking": [
      { Month: "Sep-23", "Off-Site": null, "On-Site": null, Total: null, "% Achieved": null },
      { Month: "Oct-23", "Off-Site": null, "On-Site": null, Total: null, "% Achieved": null },
      { Month: "Nov-23", "Off-Site": null, "On-Site": null, Total: null, "% Achieved": null },
      { Month: "Dec-23", "Off-Site": null, "On-Site": null, Total: null, "% Achieved": null },
      { Month: "Jan-24", "Off-Site": null, "On-Site": null, Total: null, "% Achieved": null },
      { Month: "Feb-24", "Off-Site": null, "On-Site": null, Total: null, "% Achieved": null },
      { Month: "Mar-24", "Off-Site": null, "On-Site": null, Total: null, "% Achieved": null },
      { Month: "Apr-24", "Off-Site": null, "On-Site": 3293.5, Total: 3293.5, "% Achieved": "32.8%" },
      { Month: "May-24", "Off-Site": null, "On-Site": 4070.8, Total: 4070.8, "% Achieved": "38.0%" },
      { Month: "Jun-24", "Off-Site": null, "On-Site": 4389.7, Total: 4389.7, "% Achieved": "37.8%" },
      { Month: "Jul-24", "Off-Site": 86.7, "On-Site": 4131.1, Total: 4217.8, "% Achieved": "41.5%" },
      { Month: "Aug-24", "Off-Site": null, "On-Site": null, Total: null, "% Achieved": null },
      { Month: "YTD", "Off-Site": 86.7, "On-Site": 15885.2, Total: 15971.9, "% Achieved": "37.5%" },
      { Month: "Targets", "Off-Site": null, "On-Site": null, Total: null, "% Achieved": "15.0%" },
    ],
    Indigenous: [
      { Month: "Sep-23", "Off-Site": null, "On-Site": null, Total: null, "% Achieved": null },
      { Month: "Oct-23", "Off-Site": null, "On-Site": null, Total: null, "% Achieved": null },
      { Month: "Nov-23", "Off-Site": null, "On-Site": null, Total: null, "% Achieved": null },
      { Month: "Dec-23", "Off-Site": null, "On-Site": null, Total: null, "% Achieved": null },
      { Month: "Jan-24", "Off-Site": null, "On-Site": null, Total: null, "% Achieved": null },
      { Month: "Feb-24", "Off-Site": null, "On-Site": null, Total: null, "% Achieved": null },
      { Month: "Mar-24", "Off-Site": null, "On-Site": null, Total: null, "% Achieved": null },
      { Month: "Apr-24", "Off-Site": null, "On-Site": null, Total: null, "% Achieved": null },
      { Month: "May-24", "Off-Site": null, "On-Site": null, Total: null, "% Achieved": null },
      { Month: "Jun-24", "Off-Site": null, "On-Site": null, Total: null, "% Achieved": null },
      { Month: "Jul-24", "Off-Site": null, "On-Site": null, Total: null, "% Achieved": null },
      { Month: "Aug-24", "Off-Site": null, "On-Site": null, Total: null, "% Achieved": null },
      { Month: "YTD", "Off-Site": null, "On-Site": null, Total: null, "% Achieved": null },
      { Month: "Targets", "Off-Site": null, "On-Site": null, Total: null, "% Achieved": "6%" },
    ],
  },
  "Pillar B": {
    "Non Equity Seeking": [
      {
        Month: "Sep-23",
        "Off-Site": null,
        "On-Site": null,
        Total: null,
        "% Achieved": null,
      },
      {
        Month: "Oct-23",
        "Off-Site": null,
        "On-Site": null,
        Total: null,
        "% Achieved": null,
      },
      {
        Month: "Nov-23",
        "Off-Site": null,
        "On-Site": null,
        Total: null,
        "% Achieved": null,
      },
      {
        Month: "Dec-23",
        "Off-Site": null,
        "On-Site": null,
        Total: null,
        "% Achieved": null,
      },
      {
        Month: "Jan-24",
        "Off-Site": null,
        "On-Site": null,
        Total: null,
        "% Achieved": null,
      },
      {
        Month: "Feb-24",
        "Off-Site": null,
        "On-Site": null,
        Total: null,
        "% Achieved": null,
      },
      {
        Month: "Mar-24",
        "Off-Site": null,
        "On-Site": null,
        Total: null,
        "% Achieved": null,
      },
      {
        Month: "Apr-24",
        "Off-Site": null,
        "On-Site": 249.0,
        Total: 249.0,
        "% Achieved": "2.5%",
      },
      {
        Month: "May-24",
        "Off-Site": null,
        "On-Site": 293.5,
        Total: 293.5,
        "% Achieved": "2.7%",
      },
      {
        Month: "Jun-24",
        "Off-Site": null,
        "On-Site": 453.5,
        Total: 453.5,
        "% Achieved": "3.9%",
      },
      {
        Month: "Jul-24",
        "Off-Site": null,
        "On-Site": 118.0,
        Total: 118.0,
        "% Achieved": "1.2%",
      },
      {
        Month: "Aug-24",
        "Off-Site": null,
        "On-Site": null,
        Total: null,
        "% Achieved": null,
      },
      {
        Month: "YTD",
        "Off-Site": null,
        "On-Site": 1114.0,
        Total: 1114.0,
        "% Achieved": "2.6%",
      },
      {
        Month: "Targets",
        "Off-Site": null,
        "On-Site": null,
        Total: null,
        "% Achieved": null,
      },
    ],
    Indigenous: [
      {
        Month: "Sep-23",
        "Off-Site": null,
        "On-Site": null,
        Total: null,
        "% Achieved": null,
      },
      {
        Month: "Oct-23",
        "Off-Site": null,
        "On-Site": null,
        Total: null,
        "% Achieved": null,
      },
      {
        Month: "Nov-23",
        "Off-Site": null,
        "On-Site": null,
        Total: null,
        "% Achieved": null,
      },
      {
        Month: "Dec-23",
        "Off-Site": null,
        "On-Site": null,
        Total: null,
        "% Achieved": null,
      },
      {
        Month: "Jan-24",
        "Off-Site": null,
        "On-Site": null,
        Total: null,
        "% Achieved": null,
      },
      {
        Month: "Feb-24",
        "Off-Site": null,
        "On-Site": null,
        Total: null,
        "% Achieved": null,
      },
      {
        Month: "Mar-24",
        "Off-Site": null,
        "On-Site": null,
        Total: null,
        "% Achieved": null,
      },
      {
        Month: "Apr-24",
        "Off-Site": null,
        "On-Site": null,
        Total: null,
        "% Achieved": null,
      },
      {
        Month: "May-24",
        "Off-Site": null,
        "On-Site": null,
        Total: null,
        "% Achieved": null,
      },
      {
        Month: "Jun-24",
        "Off-Site": null,
        "On-Site": null,
        Total: null,
        "% Achieved": null,
      },
      {
        Month: "Jul-24",
        "Off-Site": null,
        "On-Site": null,
        Total: null,
        "% Achieved": null,
      },
      {
        Month: "Aug-24",
        "Off-Site": null,
        "On-Site": null,
        Total: null,
        "% Achieved": null,
      },
      {
        Month: "YTD",
        "Off-Site": null,
        "On-Site": null,
        Total: null,
        "% Achieved": null,
      },
      {
        Month: "Targets",
        "Off-Site": null,
        "On-Site": "12% of 25%",
        Total: null,
        "% Achieved": "3%",
      },
    ],
    "Equity Seeking": [
      {
        Month: "Sep-23",
        "Off-Site": null,
        "On-Site": null,
        Total: null,
        "1.1 Factor": null,
        "% Achieved": null,
      },
      {
        Month: "Oct-23",
        "Off-Site": null,
        "On-Site": null,
        Total: null,
        "1.1 Factor": null,
        "% Achieved": null,
      },
      {
        Month: "Nov-23",
        "Off-Site": null,
        "On-Site": null,
        Total: null,
        "1.1 Factor": null,
        "% Achieved": null,
      },
      {
        Month: "Dec-23",
        "Off-Site": null,
        "On-Site": null,
        Total: null,
        "1.1 Factor": null,
        "% Achieved": null,
      },
      {
        Month: "Jan-24",
        "Off-Site": null,
        "On-Site": null,
        Total: null,
        "1.1 Factor": null,
        "% Achieved": null,
      },
      {
        Month: "Feb-24",
        "Off-Site": null,
        "On-Site": null,
        Total: null,
        "1.1 Factor": null,
        "% Achieved": null,
      },
      {
        Month: "Mar-24",
        "Off-Site": null,
        "On-Site": null,
        Total: null,
        "1.1 Factor": null,
        "% Achieved": null,
      },
      {
        Month: "Apr-24",
        "Off-Site": null,
        "On-Site": null,
        Total: null,
        "1.1 Factor": null,
        "% Achieved": null,
      },
      {
        Month: "May-24",
        Total: 120.0,
        "On-Site": 120.0,
        "1.1 Factor": 132.0,
        "% Achieved": "1.2%",
        "Off-Site": null,
      },
      {
        Month: "Jun-24",
        Total: 72.0,
        "On-Site": 72.0,
        "1.1 Factor": 79.2,
        "% Achieved": "0.7%",
        "Off-Site": null,
      },
      {
        Month: "Jul-24",
        Total: null,
        "On-Site": null,
        "1.1 Factor": null,
        "% Achieved": "0.0%",
        "Off-Site": null,
      },
      {
        Month: "Aug-24",
        Total: null,
        "On-Site": null,
        "1.1 Factor": null,
        "% Achieved": null,
        "Off-Site": null,
      },
      {
        Month: "YTD",
        Total: 192.0,
        "On-Site": 192.0,
        "1.1 Factor": 211.2,
        "% Achieved": "0.5%",
        "Off-Site": null,
      },
      {
        Month: "Targets",
        Total: null,
        "On-Site": null,
        "1.1 Factor": null,
        "% Achieved": "25%",
        "Off-Site": null,
      },
    ],
  },
};
