
















































import { Component, Prop } from "vue-property-decorator";
import BaseComponent from "./BaseComponent";
import SpinnerComponent from "./SpinnerComponent.vue";
import ReportDataTableComponent from "./ReportDataTableComponent.vue";
import * as _ from "lodash";
import { ColumnStyleItem, Expense, Project, RefDataValue, ReportDataTable, ReportDataTableRow } from "@/store/models";
import { formatCurrencyToString } from "@/lib/shared";
import { EmptyReportDataTable } from "@/store/models-empty";
import * as datetime from "../lib/datetime";
import { idaData } from "../lib/data/sample-data";

@Component({
  components: {
    SpinnerComponent,
    ReportDataTableComponent,
  },
})
export default class InclusionDevelopmentComponent extends BaseComponent {
  @Prop() public reportMode: any;
  @Prop() public reportHelper: any;
  @Prop() public organizationName: any;
  @Prop() public projectDetails!: { [key: string]: Project };
  @Prop() public dateTo!: string;

  private inclusionDevelopmentAgreementData: any = idaData;

  private isLoaded: boolean = false;
  private summaryTable: ReportDataTable = _.cloneDeep(EmptyReportDataTable);
  private equityEmploymentTable: ReportDataTable = _.cloneDeep(EmptyReportDataTable);
  private indigenousEmploymentTable: ReportDataTable = _.cloneDeep(EmptyReportDataTable);
  private nonEquityTradesTable: ReportDataTable = _.cloneDeep(EmptyReportDataTable);
  private equityTradesTable: ReportDataTable = _.cloneDeep(EmptyReportDataTable);
  private indigenousTradesTable: ReportDataTable = _.cloneDeep(EmptyReportDataTable);

  protected mounted() {
    this.createSummaryTable();
    this.createEquityEmploymentTable();
    this.createIndigenousEmploymentTable();
    this.createNonEquityTradesTable();
    this.createEquityTradesTable();
    this.createIndigenousTradesTable();
    this.isLoaded = true;
  }

  get project(): Project {
    return Object.values(this.projectDetails)[0];
  }

  get today(): string {
    if (this.dateTo !== "") {
      return datetime.formatDateForDisplay(this.dateTo);
    }
    return datetime.formatDateForDisplay(new Date().toDateString());
  }

  private createSummaryTable() {
    this.summaryTable.className = "margin-bottom";
    this.summaryTable.title = "Project Total";
    this.summaryTable.parentsBold = false;
    this.summaryTable.headers = [
      { text: "Month", rowLayout: 1, align: "left" },
      { text: "Off-Site", rowLayout: 2, align: "left" },
      { text: "On-Site", rowLayout: 2, align: "left" },
      { text: "Total", rowLayout: 2, align: "left" },
    ] as any[];

    const data: any[] = this.inclusionDevelopmentAgreementData["Project Total"];
    const ytd = data[data.length - 2];
    const targets = data[data.length - 1];

    for (const row of data.slice(0, data.length - 2)) {
      this.summaryTable.rows.push({
        data: [row["Month"], row["Off-Site"] ? row["Off-Site"].toString() : "-", row["On-Site"] ? row["On-Site"].toString() : "-", row["Total"] ? row["Total"].toString() : "-"],
        children: [],
      });
    }

    const boldColumnStyle: ColumnStyleItem[] = [
      { index: 0, isBold: true },
      { index: 1, isBold: true },
      { index: 2, isBold: true },
      { index: 3, isBold: true },
    ];
    this.summaryTable.rows.push({
      data: [ytd["Month"], ytd["Off-Site"] ? ytd["Off-Site"].toString() : "-", ytd["On-Site"] ? ytd["On-Site"].toString() : "-", ytd["Total"] ? ytd["Total"].toString() : "-"],
      children: [],
      columnStyle: boldColumnStyle,
    });
    this.summaryTable.rows.push({
      data: [targets["Month"], "", "", ""],
      children: [],
      columnStyle: boldColumnStyle,
    });

    this.summaryTable.totalRow = null;
  }

  private createEquityEmploymentTable() {
    this.equityEmploymentTable.className = "margin-bottom";
    this.equityEmploymentTable.title = "Equity Seeking";
    this.equityEmploymentTable.parentsBold = false;
    this.equityEmploymentTable.headers = [
      // { text: "Month", rowLayout: 1, align: "left" },
      { text: "Off-Site", rowLayout: 1, align: "left" },
      { text: "On-Site", rowLayout: 1, align: "left" },
      { text: "Total", rowLayout: 1, align: "left" },
      { text: "% Achieved", rowLayout: 1, align: "left" },
    ] as any[];

    const boldColumnStyle: ColumnStyleItem[] = [
      { index: 0, isBold: true },
      { index: 1, isBold: true },
      { index: 2, isBold: true },
      { index: 3, isBold: true },
      // { index: 4, isBold: true },
    ];

    const data: any[] = this.inclusionDevelopmentAgreementData["Pillar A"]["Equity Seeking"];
    const ytd = data[data.length - 2];
    const targets = data[data.length - 1];

    for (const row of data.slice(0, data.length - 2)) {
      const columnStyle: ColumnStyleItem[] = [];
      if (row["% Achieved"]) {
        columnStyle.push({ index: 3, textColour: "green" });
      }
      this.equityEmploymentTable.rows.push({
        data: [
          // row["Month"],
          row["Off-Site"] ? row["Off-Site"].toString() : "-",
          row["On-Site"] ? row["On-Site"].toString() : "-",
          row["Total"] ? row["Total"].toString() : "-",
          row["% Achieved"] ? row["% Achieved"] : "-",
        ],
        children: [],
        columnStyle,
      });
    }

    this.equityEmploymentTable.rows.push({
      data: [ytd["Off-Site"] ? ytd["Off-Site"].toString() : "-", ytd["On-Site"] ? ytd["On-Site"].toString() : "-", ytd["Total"] ? ytd["Total"].toString() : "-", ytd["% Achieved"] ? ytd["% Achieved"] : "-"],
      children: [],
      columnStyle: [...boldColumnStyle.slice(0, 3), { index: 3, textColour: "green", isBold: true }],
    });
    this.equityEmploymentTable.rows.push({
      data: ["", "", "", targets["% Achieved"]],
      children: [],
      columnStyle: boldColumnStyle,
    });

    this.equityEmploymentTable.totalRow = null;
  }

  private createIndigenousEmploymentTable() {
    this.indigenousEmploymentTable.className = "margin-bottom";
    this.indigenousEmploymentTable.title = "Indigenous";
    this.indigenousEmploymentTable.parentsBold = false;
    this.indigenousEmploymentTable.headers = [
      // { text: "Month", rowLayout: 1, align: "left" },
      { text: "Off-Site", rowLayout: 1, align: "left" },
      { text: "On-Site", rowLayout: 1, align: "left" },
      { text: "Total", rowLayout: 1, align: "left" },
      { text: "% Achieved", rowLayout: 1, align: "left" },
    ] as any[];

    const boldColumnStyle: ColumnStyleItem[] = [
      { index: 0, isBold: true },
      { index: 1, isBold: true },
      { index: 2, isBold: true },
      { index: 3, isBold: true },
      // { index: 4, isBold: true },
    ];

    const data: any[] = this.inclusionDevelopmentAgreementData["Pillar A"]["Indigenous"];
    const ytd = data[data.length - 2];
    const targets = data[data.length - 1];

    for (const row of data.slice(0, data.length - 2)) {
      this.indigenousEmploymentTable.rows.push({
        data: [
          // row["Month"],
          row["Off-Site"] ? row["Off-Site"].toString() : "-",
          row["On-Site"] ? row["On-Site"].toString() : "-",
          row["Total"] ? row["Total"].toString() : "-",
          row["% Achieved"] ? row["% Achieved"] : "-",
        ],
        children: [],
      });
    }

    this.indigenousEmploymentTable.rows.push({
      data: [ytd["Off-Site"] ? ytd["Off-Site"].toString() : "-", ytd["On-Site"] ? ytd["On-Site"].toString() : "-", ytd["Total"] ? ytd["Total"].toString() : "-", ytd["% Achieved"] ? ytd["% Achieved"] : "-"],
      children: [],
      columnStyle: boldColumnStyle,
    });
    this.indigenousEmploymentTable.rows.push({
      data: ["", "", "", targets["% Achieved"]],
      children: [],
      columnStyle: boldColumnStyle,
    });

    this.indigenousEmploymentTable.totalRow = null;
  }

  private createNonEquityTradesTable() {
    this.nonEquityTradesTable.className = "margin-bottom";
    this.nonEquityTradesTable.title = "Non Equity Seeking";
    this.nonEquityTradesTable.parentsBold = false;
    this.nonEquityTradesTable.headers = [
      // { text: "Month", rowLayout: 1, align: "left" },
      { text: "Off-Site", rowLayout: 1, align: "left" },
      { text: "On-Site", rowLayout: 1, align: "left" },
      { text: "Total", rowLayout: 1, align: "left" },
      { text: "% Achieved", rowLayout: 1, align: "left" },
    ] as any[];

    const boldColumnStyle: ColumnStyleItem[] = [
      { index: 0, isBold: true },
      { index: 1, isBold: true },
      { index: 2, isBold: true },
      { index: 3, isBold: true },
      // { index: 4, isBold: true },
    ];

    const data: any[] = this.inclusionDevelopmentAgreementData["Pillar B"]["Non Equity Seeking"];
    const ytd = data[data.length - 2];
    const targets = data[data.length - 1];

    for (const row of data.slice(0, data.length - 2)) {
      this.nonEquityTradesTable.rows.push({
        data: [
          // row["Month"],
          row["Off-Site"] ? row["Off-Site"].toString() : "-",
          row["On-Site"] ? row["On-Site"].toString() : "-",
          row["Total"] ? row["Total"].toString() : "-",
          row["% Achieved"] ? row["% Achieved"] : "-",
        ],
        children: [],
      });
    }

    this.nonEquityTradesTable.rows.push({
      data: [ytd["Off-Site"] ? ytd["Off-Site"].toString() : "-", ytd["On-Site"] ? ytd["On-Site"].toString() : "-", ytd["Total"] ? ytd["Total"].toString() : "-", ytd["% Achieved"] ? ytd["% Achieved"] : "-"],
      children: [],
      columnStyle: boldColumnStyle,
    });
    this.nonEquityTradesTable.rows.push({
      data: ["", "", "", targets["% Achieved"] ? targets["% Achieved"] : "-"],
      children: [],
      columnStyle: boldColumnStyle,
    });

    this.nonEquityTradesTable.totalRow = null;
  }

  private createEquityTradesTable() {
    this.equityTradesTable.className = "margin-bottom";
    this.equityTradesTable.title = "Equity Seeking";
    this.equityTradesTable.parentsBold = false;
    this.equityTradesTable.headers = [
      // { text: "Month", rowLayout: 1, align: "left" },
      { text: "Off-Site", rowLayout: 1, align: "left" },
      { text: "On-Site", rowLayout: 1, align: "left" },
      { text: "Total", rowLayout: 1, align: "left" },
      { text: "% Achieved", rowLayout: 1, align: "left" },
      { text: "1.1 Factor", rowLayout: 1, align: "left" },
    ] as any[];

    const boldColumnStyle: ColumnStyleItem[] = [
      { index: 0, isBold: true },
      { index: 1, isBold: true },
      { index: 2, isBold: true },
      { index: 3, isBold: true },
      { index: 4, isBold: true },
      // { index: 5, isBold: true },
    ];

    const data: any[] = this.inclusionDevelopmentAgreementData["Pillar B"]["Equity Seeking"];
    const ytd = data[data.length - 2];
    const targets = data[data.length - 1];

    for (const row of data.slice(0, data.length - 2)) {
      this.equityTradesTable.rows.push({
        data: [
          // row["Month"],
          row["Off-Site"] ? row["Off-Site"].toString() : "-",
          row["On-Site"] ? row["On-Site"].toString() : "-",
          row["Total"] ? row["Total"].toString() : "-",
          row["% Achieved"] ? row["% Achieved"] : "-",
          row["1.1 Factor"] ? row["1.1 Factor"].toString() : "-",
        ],
        children: [],
      });
    }

    this.equityTradesTable.rows.push({
      data: [
        // ytd["Month"],
        ytd["Off-Site"] ? ytd["Off-Site"].toString() : "-",
        ytd["On-Site"] ? ytd["On-Site"].toString() : "-",
        ytd["Total"] ? ytd["Total"].toString() : "-",
        ytd["% Achieved"] ? ytd["% Achieved"] : "-",
        ytd["1.1 Factor"] ? ytd["1.1 Factor"].toString() : "-",
      ],
      children: [],
      columnStyle: boldColumnStyle,
    });
    this.equityTradesTable.rows.push({
      data: ["", "", "", targets["% Achieved"] ? targets["% Achieved"] : "-", ""],
      children: [],
      columnStyle: boldColumnStyle,
    });

    this.equityTradesTable.totalRow = null;
  }

  private createIndigenousTradesTable() {
    this.indigenousTradesTable.className = "margin-bottom";
    this.indigenousTradesTable.title = "Indigenous";
    this.indigenousTradesTable.parentsBold = false;
    this.indigenousTradesTable.headers = [
      // { text: "Month", rowLayout: 1, align: "left" },
      { text: "Off-Site", rowLayout: 1, align: "left" },
      { text: "On-Site", rowLayout: 1, align: "left" },
      { text: "Total", rowLayout: 1, align: "left" },
      { text: "% Achieved", rowLayout: 1, align: "left" },
    ] as any[];

    const boldColumnStyle: ColumnStyleItem[] = [
      { index: 0, isBold: true },
      { index: 1, isBold: true },
      { index: 2, isBold: true },
      { index: 3, isBold: true },
      // { index: 4, isBold: true },
    ];

    const data: any[] = this.inclusionDevelopmentAgreementData["Pillar B"]["Indigenous"];
    const ytd = data[data.length - 2];
    const targets = data[data.length - 1];

    for (const row of data.slice(0, data.length - 2)) {
      this.indigenousTradesTable.rows.push({
        data: [
          // row["Month"],
          row["Off-Site"] ? row["Off-Site"].toString() : "-",
          row["On-Site"] ? row["On-Site"].toString() : "-",
          row["Total"] ? row["Total"].toString() : "-",
          row["% Achieved"] ? row["% Achieved"] : "-",
        ],
        children: [],
      });
    }

    this.indigenousTradesTable.rows.push({
      data: [ytd["Off-Site"] ? ytd["Off-Site"].toString() : "-", ytd["On-Site"] ? ytd["On-Site"].toString() : "-", ytd["Total"] ? ytd["Total"].toString() : "-", ytd["% Achieved"] ? ytd["% Achieved"] : "-"],
      children: [],
      columnStyle: boldColumnStyle,
    });
    this.indigenousTradesTable.rows.push({
      data: ["", targets["On-Site"], "", targets["% Achieved"] ? targets["% Achieved"] : "-"],
      children: [],
      columnStyle: boldColumnStyle,
    });

    this.indigenousTradesTable.totalRow = null;
  }
}
