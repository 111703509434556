












































import { Component, Prop } from "vue-property-decorator";
import BaseComponent from "./BaseComponent";
import SpinnerComponent from "./SpinnerComponent.vue";
import ReportDataTableComponent from "./ReportDataTableComponent.vue";
import TargetComponent from "./TargetComponent.vue";
import { ExpenseAggregate, ShortRecord, Project, EmploymentAggregate, Vendor, DiversityDeclarationsMeta, Expense, ReportDataTable } from "../store/models";
import { EmptyReportDataTable } from "../store/models-empty";
import * as _ from "lodash";
import ReportHelper from "./ReportHelper";
import { formatCurrencyToString } from "@/lib/shared";

@Component({
  components: {
    SpinnerComponent,
    ReportDataTableComponent,
    TargetComponent,
  },
})
export default class PehtaBreakdownReportComponent extends BaseComponent {
  @Prop() public projectDetails!: { [key: string]: Project };
  @Prop() public pehtaData!: {
    vendorContributions: Expense[];
    communityContributions: ExpenseAggregate[];
    employment: EmploymentAggregate[];
    totalEmployment: EmploymentAggregate[];
    totalIndigenousEmployment: EmploymentAggregate[];
    expenses: ExpenseAggregate[];
    vendors: Vendor[];
    vendorExpenses: ExpenseAggregate[];
    project: ExpenseAggregate[];
  };
  @Prop() public indigenousCommunities!: ShortRecord[];
  @Prop() public secure!: boolean;
  @Prop() public procurementToggle!: boolean;

  @Prop() public reportHelper: any;
  @Prop() public reportMode: any;

  private procurementTables = {
    vendors: _.cloneDeep(EmptyReportDataTable),
    expenses: _.cloneDeep(EmptyReportDataTable),
    indigenousSpend: _.cloneDeep(EmptyReportDataTable),
    nonIndigenousSpend: _.cloneDeep(EmptyReportDataTable),
  };

  private employmentTables = {
    communities: _.cloneDeep(EmptyReportDataTable),
    indigenousEmployment: _.cloneDeep(EmptyReportDataTable),
    nonIndigenousEmployment: _.cloneDeep(EmptyReportDataTable),
    undefinedEmployment: _.cloneDeep(EmptyReportDataTable),
  };

  private benefitsTables = {
    expenses: _.cloneDeep(EmptyReportDataTable),
    communities: _.cloneDeep(EmptyReportDataTable),
    indigenousBenefits: _.cloneDeep(EmptyReportDataTable),
  };
  private summaryTable = _.cloneDeep(EmptyReportDataTable);
  private summaryTotalTable = _.cloneDeep(EmptyReportDataTable);
  private communities: ShortRecord[] = [];
  private isLoaded: boolean = false;

  get project(): Project {
    return this.projectDetails["record"];
  }

  get projectExpenses(): ExpenseAggregate | undefined {
    return this.pehtaData.project.find((project) => project.identifier === (this.project.project.ownerVendor as ShortRecord).identifier);
  }

  get totalSpend(): number {
    const projectExpenses = this.pehtaData.project.find((project) => project.identifier === (this.project.project.ownerVendor as ShortRecord).identifier);
    if (projectExpenses) {
      return projectExpenses.payload.expenses.expenseTotals.totalSpend;
    }
    return 0;
  }

  get totalIndigenousSpend(): number {
    const projectExpenses = this.pehtaData.project.find((project) => project.identifier === (this.project.project.ownerVendor as ShortRecord).identifier);
    if (projectExpenses) {
      return projectExpenses.payload.expenses.diverseSpend.totalDirectIndigenousSpend;
    }
    return 0;
  }

  get totalSpendString(): string {
    return formatCurrencyToString(this.totalSpend.toFixed(2));
  }

  get totalWagesString(): string {
    let totalWages = 0;
    for (const vendor of this.pehtaData.totalEmployment) {
      totalWages += vendor.payload.employment.totalWages;
    }
    return formatCurrencyToString(totalWages.toFixed(2));
  }

  protected mounted() {
    this.createProcurementTables();
    this.createEmploymentTables();
    this.createBenefitsTables();
    this.createSummaryTable();
    this.isLoaded = true;
  }

  private recordCommunity(community: ShortRecord) {
    if (!this.communities.find((c) => c.identifier === community.identifier)) {
      this.communities.push(community);
    }
  }

  private createProcurementTables() {
    this.procurementTables.vendors.className = "margin-bottom";
    this.procurementTables.vendors.headers = [
      { text: "Community Relationships", rowLayout: 1, align: "left" },
      { text: "Vendor", rowLayout: 1, align: "left" },
      { text: "Type", rowLayout: 1, align: "left" },
      { text: "Spend", rowLayout: 1, align: "left" },
      { text: "Indigenous Percentage", rowLayout: 1, align: "left" },
      { text: "Total Percentage", rowLayout: 1, align: "left" },
    ] as any[];
    this.procurementTables.vendors.parentsBold = false;
    this.procurementTables.vendors.totalRow = null;

    const rowData: any[] = [];
    for (const vendor of this.pehtaData.vendorExpenses.sort((a, b) => (a.displayName > b.displayName ? 1 : -1))) {
      // Include only vendors that have proxyPayees
      for (const benficiary of vendor.payload.expenses.diverseSpend.indigenousDetail.beneficiaries) {
        const community = benficiary.displayName;
        const vendorSpend = benficiary.amount;
        const vendorDetails = this.pehtaData.vendors.find((v) => (v.common ? v.common.identifier : undefined) === vendor.identifier);
        rowData.push([
          community,
          vendor.displayName,
          vendorDetails ? this.getSupplyChainDiversityDescription(vendorDetails.diversityDeclarations) : "",
          formatCurrencyToString(vendorSpend.toFixed(2)),
          ((vendorSpend / this.totalIndigenousSpend) * 100).toFixed(2) + "%",
          ((vendorSpend / this.totalSpend) * 100).toFixed(2) + "%",
        ]);
      }
    }
    for (const data of rowData.sort((a, b) => {
      if (a[0] !== b[0]) {
        return a[0] > b[0] ? 1 : -1;
      }
      return a[1] > b[1] ? 1 : -1;
    })) {
      this.procurementTables.vendors.rows.push({
        data,
        children: [],
      });
    }

    this.procurementTables.expenses.className = "margin-bottom";
    this.procurementTables.expenses.headers = [
      { text: "Total Community Procurement", rowLayout: 1, align: "left" },
      { text: "", rowLayout: 1, align: "left" },
      { text: "", rowLayout: 1, align: "left" },
      { text: "", rowLayout: 1, align: "left" },
      { text: "", rowLayout: 1, align: "left" },
      { text: "", rowLayout: 1, align: "left" },
    ] as any[];
    this.procurementTables.expenses.parentsBold = false;
    this.procurementTables.expenses.totalRow = null;

    for (const expense of this.pehtaData.expenses.sort((a, b) => (a.displayName > b.displayName ? 1 : -1))) {
      if (expense.identifier && expense.identifier !== "urn:nisto-link:id:non-indigenous:no-999999") {
        const community = { displayName: expense.displayName, identifier: expense.identifier };
        this.recordCommunity(community);
        this.procurementTables.expenses.rows.push({
          data: [
            community.displayName,
            "",
            "",
            formatCurrencyToString(expense.payload.expenses.expenseTotals.totalSpend.toFixed(2)),
            ((expense.payload.expenses.expenseTotals.totalSpend / this.totalIndigenousSpend) * 100).toFixed(2) + "%",
            ((expense.payload.expenses.expenseTotals.totalSpend / this.totalSpend) * 100).toFixed(2) + "%",
          ],
          children: [],
        });
      }
    }

    this.procurementTables.indigenousSpend.className = "margin-bottom";
    this.procurementTables.indigenousSpend.headers = [
      { text: "", rowLayout: 1, align: "left" },
      { text: "", rowLayout: 1, align: "left" },
      { text: "", rowLayout: 1, align: "left" },
      { text: "", rowLayout: 1, align: "left" },
      { text: "", rowLayout: 1, align: "left" },
      { text: "", rowLayout: 1, align: "left" },
    ] as any[];
    this.procurementTables.indigenousSpend.parentsBold = false;
    this.procurementTables.indigenousSpend.totalRow = null;

    this.procurementTables.indigenousSpend.rows.push({
      data: ["Total Indigenous Spend", "", "", formatCurrencyToString(this.totalIndigenousSpend.toFixed(2)), "", ((this.totalIndigenousSpend / this.totalSpend) * 100 || 0).toFixed(2) + "%"],
      children: [],
    });

    this.procurementTables.nonIndigenousSpend.className = "margin-bottom";
    this.procurementTables.nonIndigenousSpend.headers = [
      { text: "", rowLayout: 1, align: "left" },
      { text: "", rowLayout: 1, align: "left" },
      { text: "", rowLayout: 1, align: "left" },
      { text: "", rowLayout: 1, align: "left" },
      { text: "", rowLayout: 1, align: "left" },
      { text: "", rowLayout: 1, align: "left" },
    ] as any[];
    this.procurementTables.nonIndigenousSpend.parentsBold = false;
    this.procurementTables.nonIndigenousSpend.totalRow = null;

    this.procurementTables.nonIndigenousSpend.rows.push({
      data: [
        "Total Non-Indigenous Spend",
        "",
        "",
        formatCurrencyToString((this.totalSpend - this.totalIndigenousSpend).toFixed(2)),
        "",
        (((this.totalSpend - this.totalIndigenousSpend) / this.totalSpend) * 100 || 0).toFixed(2) + "%",
      ],
      children: [],
    });

    if (!this.procurementToggle) {
      this.removeLastColumn(this.procurementTables.vendors);
      this.removeLastColumn(this.procurementTables.expenses);
      this.removeLastColumn(this.procurementTables.indigenousSpend);
    }
  }

  private createEmploymentTables() {
    this.employmentTables.communities.className = "margin-bottom";
    this.employmentTables.communities.headers = [
      { text: "Community", rowLayout: 1, align: "left" },
      { text: "Headcount", rowLayout: 1, align: "left" },
      { text: "Employment Hours", rowLayout: 1, align: "left" },
      { text: "Employment", rowLayout: 1, align: "left" },
      { text: "Percentage", rowLayout: 1, align: "left" },
    ] as any[];
    this.employmentTables.communities.parentsBold = false;
    this.employmentTables.communities.totalRow = null;

    const nonIndigenousRecord = this.pehtaData.totalIndigenousEmployment.find((e) => e.identifier === "no") || { payload: { employment: { headcount: 0, totalManHours: 0, totalWages: 0 } } };
    const undefinedRecord = this.pehtaData.totalIndigenousEmployment.find((e) => e.identifier === "unknown") || { payload: { employment: { headcount: 0, totalManHours: 0, totalWages: 0 } } };
    const indigenousRecord = this.pehtaData.totalIndigenousEmployment.find((e) => e.identifier === "yes") || { payload: { employment: { headcount: 0, totalManHours: 0, totalWages: 0 } } };

    const totalWages = nonIndigenousRecord.payload.employment.totalWages + indigenousRecord.payload.employment.totalWages + undefinedRecord.payload.employment.totalWages;

    for (const vendor of this.pehtaData.employment.sort((a, b) => (a.displayName > b.displayName ? 1 : -1))) {
      if (vendor.identifier !== "urn:nisto-link:id:non-indigenous:no-999999") {
        const community = { displayName: vendor.displayName, identifier: vendor.identifier };
        this.recordCommunity(community);
        const vendorWages = vendor.payload.employment.totalWages;
        this.employmentTables.communities.rows.push({
          data: [
            community.displayName,
            vendor.payload.employment.headcount.toString(),
            vendor.payload.employment.totalManHours.toString(),
            formatCurrencyToString(vendorWages.toFixed(2)),
            ((vendorWages / totalWages) * 100).toFixed(2) + "%",
          ],
          children: [],
        });
      }
    }

    this.employmentTables.indigenousEmployment.className = "margin-bottom";
    this.employmentTables.indigenousEmployment.headers = [
      { text: "", rowLayout: 1, align: "left" },
      { text: "", rowLayout: 1, align: "left" },
      { text: "", rowLayout: 1, align: "left" },
      { text: "", rowLayout: 1, align: "left" },
      { text: "", rowLayout: 1, align: "left" },
    ] as any[];
    this.employmentTables.indigenousEmployment.parentsBold = false;
    this.employmentTables.indigenousEmployment.totalRow = null;

    this.employmentTables.indigenousEmployment.rows.push({
      data: [
        "Total Indigenous Employment",
        indigenousRecord.payload.employment.headcount.toString(),
        indigenousRecord.payload.employment.totalManHours.toString(),
        formatCurrencyToString(indigenousRecord.payload.employment.totalWages.toFixed(2)),
        ((indigenousRecord.payload.employment.totalWages / totalWages) * 100 || 0).toFixed(2) + "%",
      ],
      children: [],
    });

    this.employmentTables.nonIndigenousEmployment.className = "margin-bottom";
    this.employmentTables.nonIndigenousEmployment.headers = [
      { text: "", rowLayout: 1, align: "left" },
      { text: "", rowLayout: 1, align: "left" },
      { text: "", rowLayout: 1, align: "left" },
      { text: "", rowLayout: 1, align: "left" },
      { text: "", rowLayout: 1, align: "left" },
    ] as any[];
    this.employmentTables.nonIndigenousEmployment.parentsBold = false;
    this.employmentTables.nonIndigenousEmployment.totalRow = null;

    this.employmentTables.nonIndigenousEmployment.rows.push({
      data: [
        "Total Non-Indigenous Employment",
        nonIndigenousRecord.payload.employment.headcount.toString(),
        nonIndigenousRecord.payload.employment.totalManHours.toString(),
        formatCurrencyToString(nonIndigenousRecord.payload.employment.totalWages.toFixed(2)),
        ((nonIndigenousRecord.payload.employment.totalWages / totalWages) * 100 || 0).toFixed(2) + "%",
      ],
      children: [],
    });

    this.employmentTables.undefinedEmployment.className = "margin-bottom";
    this.employmentTables.undefinedEmployment.headers = [
      { text: "", rowLayout: 1, align: "left" },
      { text: "", rowLayout: 1, align: "left" },
      { text: "", rowLayout: 1, align: "left" },
      { text: "", rowLayout: 1, align: "left" },
      { text: "", rowLayout: 1, align: "left" },
    ] as any[];
    this.employmentTables.undefinedEmployment.parentsBold = false;
    this.employmentTables.undefinedEmployment.totalRow = null;

    this.employmentTables.undefinedEmployment.rows.push({
      data: [
        "Total Undefined Employment",
        undefinedRecord.payload.employment.headcount.toString(),
        undefinedRecord.payload.employment.totalManHours.toString(),
        formatCurrencyToString(undefinedRecord.payload.employment.totalWages.toFixed(2)),
        ((undefinedRecord.payload.employment.totalWages / totalWages) * 100 || 0).toFixed(2) + "%",
      ],
      children: [],
    });
  }

  private createBenefitsTables() {
    this.benefitsTables.communities.className = "margin-bottom";
    this.benefitsTables.communities.headers = [
      { text: "Community", rowLayout: 1, align: "left" },
      { text: "Payee", rowLayout: 1, align: "left" },
      { text: "Type", rowLayout: 1, align: "left" },
      { text: "Amount", rowLayout: 1, align: "left" },
      { text: "Comments", rowLayout: 1, align: "left" },
    ] as any[];
    this.benefitsTables.communities.parentsBold = false;
    this.benefitsTables.communities.totalRow = null;

    const rowData: any[] = [];
    for (const expense of this.pehtaData.vendorContributions) {
      if (expense.payment.hasBeneficiary) {
        const community = expense.payment.hasBeneficiary.displayName;
        const vendorSpend = expense.payment.amount as number;
        const vendor = expense.payment.hasPayee ? (expense.payment.hasPayee as any).identifier : "";
        const vendorDetails = this.pehtaData.vendors.find((v) => (v.common ? v.common.identifier : undefined) === vendor);

        rowData.push([
          community,
          vendorDetails ? (vendorDetails.common ? vendorDetails.common.displayName : "") : "",
          vendorDetails ? this.getSupplyChainDiversityDescription(vendorDetails.diversityDeclarations) : "",
          formatCurrencyToString(vendorSpend.toFixed(2)),
          expense.payment.description,
        ]);
      }
    }

    for (const data of rowData.sort((a, b) => {
      if (a[0] !== b[0]) {
        return a[0] > b[0] ? 1 : -1;
      }
      return a[1] > b[1] ? 1 : -1;
    })) {
      this.benefitsTables.communities.rows.push({
        data,
        children: [],
      });
    }

    this.benefitsTables.expenses.className = "margin-bottom";
    this.benefitsTables.expenses.headers = [
      { text: "", rowLayout: 1, align: "left" },
      { text: "", rowLayout: 1, align: "left" },
      { text: "", rowLayout: 1, align: "left" },
      { text: "", rowLayout: 1, align: "left" },
      { text: "", rowLayout: 1, align: "left" },
    ] as any[];
    this.benefitsTables.expenses.parentsBold = false;
    this.benefitsTables.expenses.totalRow = null;

    for (const contribution of this.pehtaData.communityContributions.sort((a, b) => (a.displayName > b.displayName ? 1 : -1))) {
      if (contribution.identifier && contribution.identifier !== "urn:nisto-link:id:non-indigenous:no-999999") {
        const community = { displayName: contribution.displayName, identifier: contribution.identifier };
        this.recordCommunity(community);
        this.benefitsTables.expenses.rows.push({
          data: [community.displayName, "", "", formatCurrencyToString(contribution.payload.expenses.diverseSpend.totalIndigenousSpend.toFixed(2)), ""],
          children: [],
        });
      }
    }

    this.benefitsTables.indigenousBenefits.className = "margin-bottom";
    this.benefitsTables.indigenousBenefits.headers = [
      { text: "", rowLayout: 1, align: "left" },
      { text: "", rowLayout: 1, align: "left" },
      { text: "", rowLayout: 1, align: "left" },
      { text: "", rowLayout: 1, align: "left" },
      { text: "", rowLayout: 1, align: "left" },
    ] as any[];
    this.benefitsTables.indigenousBenefits.parentsBold = false;
    this.benefitsTables.indigenousBenefits.totalRow = null;

    const benefitTotal = this.pehtaData.communityContributions
      .filter((e) => {
        return e.identifier && e.identifier !== "urn:nisto-link:id:non-indigenous:no-999999";
      })
      .map((e) => e.payload.expenses.diverseSpend.totalIndigenousSpend)
      .reduce((a, b) => a + b, 0);
    this.benefitsTables.indigenousBenefits.rows.push({
      data: ["Total Indigenous Community Benefit", "", "", formatCurrencyToString(benefitTotal.toFixed(2)), ""],
      children: [],
    });
  }

  private createSummaryTable() {
    this.summaryTable.className = "margin-bottom";
    this.summaryTable.headers = [
      { text: "Community", rowLayout: 1, align: "left" },
      { text: "Procurement", rowLayout: 1, align: "left" },
      { text: "Employment", rowLayout: 1, align: "left" },
      { text: "Benefits", rowLayout: 2, align: "left" },
    ] as any[];
    if (this.secure) {
      this.summaryTable.headers.pop();
    }
    this.summaryTable.parentsBold = false;
    this.summaryTable.totalRow = null;

    const totals = {
      procurement: 0,
      employment: 0,
      contributions: 0,
    };
    for (const community of this.communities.sort((a, b) => ((a.displayName as string) > (b.displayName as string) ? 1 : -1))) {
      const procurement = this.pehtaData.expenses.find((expense) => expense.identifier === community.identifier);
      const employment = this.pehtaData.employment.find((e) => e.identifier === community.identifier);
      const contributions = this.pehtaData.communityContributions.find((contribution) => contribution.identifier === community.identifier);

      totals.procurement += procurement ? procurement.payload.expenses.expenseTotals.totalSpend : 0;
      totals.employment += employment ? employment.payload.employment.totalWages : 0;
      totals.contributions += contributions ? contributions.payload.expenses.diverseSpend.totalIndigenousSpend : 0;

      const data = [
        community.displayName,
        procurement ? formatCurrencyToString(procurement.payload.expenses.expenseTotals.totalSpend.toFixed(2)) : "",
        employment ? formatCurrencyToString(employment.payload.employment.totalWages.toFixed(2)) : "",
      ];
      if (!this.secure) {
        data.push(contributions ? formatCurrencyToString(contributions.payload.expenses.diverseSpend.totalIndigenousSpend.toFixed(2)) : "");
      }

      this.summaryTable.rows.push({
        data,
        children: [],
      });
    }

    this.summaryTotalTable.className = "margin-bottom";
    this.summaryTotalTable.headers = [
      { text: "", rowLayout: 1, align: "left" },
      { text: "", rowLayout: 1, align: "left" },
      { text: "", rowLayout: 1, align: "left" },
      { text: "", rowLayout: 2, align: "left" },
    ] as any[];
    if (this.secure) {
      this.summaryTotalTable.headers.pop();
    }
    this.summaryTotalTable.parentsBold = false;
    this.summaryTotalTable.totalRow = null;

    if (this.secure) {
      this.summaryTotalTable.rows.push({
        data: ["Total", formatCurrencyToString(totals.procurement.toFixed(2)), formatCurrencyToString(totals.employment.toFixed(2))],
        children: [],
      });
    } else {
      this.summaryTotalTable.rows.push({
        data: ["Total", formatCurrencyToString(totals.procurement.toFixed(2)), formatCurrencyToString(totals.employment.toFixed(2)), formatCurrencyToString(totals.contributions.toFixed(2))],
        children: [],
      });
    }
  }

  private getSupplyChainDiversityDescription(declarations: DiversityDeclarationsMeta): string {
    const indigenousImpact = declarations.indigenousImpact ? declarations.indigenousImpact.values : [];
    const impactESG = declarations.impactESG ? declarations.impactESG.values : [];
    const socialFocusedOrgs = declarations.socialFocusedOrgs ? declarations.socialFocusedOrgs.values : [];

    if (indigenousImpact.includes("indigenousOwned")) {
      return "Indigenous Community Owned Business";
    }
    if (impactESG.includes("indigenousOwned")) {
      return "Certified Indigenous Owned Business";
    }
    if (socialFocusedOrgs.includes("indigenousFocused")) {
      return "Self-Declared Indigenous Owned Business";
    }
    return "Community Relationship";
  }

  private getCommunityById(id: string): ShortRecord {
    return this.indigenousCommunities.find((community) => community.identifier === id) as ShortRecord;
  }

  private hasRowData(table: ReportDataTable): boolean {
    return table.rows.length > 0;
  }

  private removeLastColumn(table: ReportDataTable) {
    table.headers.pop();
    for (const row of table.rows) {
      row.data.pop();
    }
  }
}
